import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import api from "../api";
import Fade from "react-reveal/Fade";
import config from "../config";

class EventCard extends Component {
  render() {
    return (
      <div className={"event-card"}>
        <div class="uk-card uk-card-default uk-card-body">
          <div className={"content"}>
            <div>
              <h2>{this.props.data.title}</h2>
            </div>
            <div>
              <h3>{this.props.data.date}</h3>
            </div>
            <div>
              <p>{this.props.data.description}</p>
            </div>
          </div>
          <div className={"image"}>
            {!this.props.data.img.includes("nil") ? (
              <img src={this.props.data.img} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

class Bulletin extends Component {
  state = {
    events: null
  };

  async componentDidMount() {
    const events = await api.readEvents();
    this.setState({ events });
  }

  render() {
    return (
      <div>
        <Header />
        <Fade delay={config.fadeDelay} duration={config.fadeDuration}>
          <div className={"bulletin"}>
            <div class="uk-margin-medium uk-card uk-card-default uk-card-body">
              <article class="uk-article">
                <h1 class="uk-article-title">
                  <a class="uk-link-reset" href="">
                    Events
                  </a>
                </h1>
                {this.state.events ? (
                  Object.values(this.state.events).map(el => (
                    <EventCard data={el} />
                  ))
                ) : (
                  <center>
                    <div uk-spinner="ratio: 10" />
                  </center>
                )}
              </article>
            </div>
          </div>
        </Fade>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bulletin);
