import React, { Component } from "react";

export default class VideoPlayer extends Component {
  state = {
    videoStyle: { opacity: 0, position: "absolute" },
    spinner: <div uk-spinner="ratio: 10" />
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ spinner: <div />, videoStyle: { opacity: 100 } });
    }, 0);
  }

  render() {
    return (
      <div className={"video-player"}>
        <div className={"video-container"}>
          <h1>{this.props.video.title.replace(".mp4", "")}</h1>
          {this.state.spinner}
          <video style={this.state.videoStyle} controls>
            <source src={this.props.video.path} type={"video/mp4"} />
          </video>
        </div>
        <div className={"text-block"}>
          <h3>{this.props.video.modifiedDate}</h3>
          <h2>{this.props.desc.title}</h2>
          <p>{this.props.desc.description}</p>
        </div>
      </div>
    );
  }
}
