import React, { Component } from "react";
import Is from "is_js";
import Img from "../img/music_icon-dark.png";
import Img2 from "../img/play-ring.png";

export default class MediaPlayer extends Component {
  state = { isPlaying: false, oldUrl: "" };

  componentDidUpdate() {
    this.state.oldUrl = this.props.url;
    const audio = document.getElementById("audio_player");
    if (audio) {
      audio.onplay = () => {
        this.setState({ isPlaying: true });
      };
      audio.onpause = () => {
        this.setState({ isPlaying: false });
      };
    }
  }

  router() {
    if (this.props.title.length > 0) {
      if (this.state.oldUrl !== this.props.url) {
        this.state.isPlaying = false;
      }
      return (
        <div>
          <div className={"media-player-icon"}>
            <img
              style={this.state.isPlaying ? { opacity: 1 } : { opacity: 0 }}
              className={"ring"}
              src={Img2}
            />
            <img className={"icon"} src={Img} />
          </div>
          <div>
            <h1>{this.props.title}</h1>
            <h2>{this.props.subTitle}</h2>
            <audio
              style={Is.safari() ? { border: "0px" } : {}}
              id={"audio_player"}
              src={this.props.url}
              controls
            />
            <h3>{this.props.date}</h3>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    return <div className={"media-player"}>{this.router()}</div>;
  }
}
