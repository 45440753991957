module.exports = {
	fadeDelay: 500,
	fadeDuration: 1000,
	autoScrollToTop: true,
	protocol:
		process.env.NODE_ENV === "production" && false ? "http://" : "http://",
	api:
		process.env.NODE_ENV === "production" && false
			? "70.32.30.224"
			: "70.32.30.224",
	port: process.env.NODE_ENV === "production" && false ? ":5000" : ":5000",
};
