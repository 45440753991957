import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import Divider from "./divider";
import Fade from "react-reveal/Fade";
import CardTable from "./card_table";
import api from "../api";
import config from "../config";

const our_approach = <div>our approach</div>;

class FAQ extends Component {
  state = {
    section: "our_approach",
    faq: []
  };

  async componentDidMount() {
    const faq = await api.readFAQ();
    this.setState({ faq: Object.values(faq) });
  }

  renderRouter() {
    switch (this.state.section) {
      case "our_approach":
        return (
          <div>
            <i class="far fa-compass" />
            <h1>Our Approach</h1>
            <div className={"title-text"}>
              Faith follows fact. Biblical faith is the result of an examination
              of the pertinent facts. It is the "conviction of things not seen"
              (Hebrews 11:1) based on the evidence already presented. For this
              reason we seek to strengthen our faith through greater
              understanding and the practice of those principles in our own
              lives. Truth must be known before it can be believed.
            </div>
          </div>
        );
      case "our_beliefs":
        return (
          <div>
            <i class="fab fa-readme" />
            <h1>Our Beliefs</h1>
            <div className={"title-text"}>
              We have no human creeds. We believe the Scriptures were originally
              inerrant and continue so in as much as they have been accurately
              translated. The Bible teaches that a genuine Faith that Jesus is
              Lord/God is a prerequisite for relationship with God and produces:
              <ul>
                <li>
                  A confession before others of that same belief (Romans
                  10:9-10)
                </li>
                <li>Repentance from sin and (Luke 24:47)</li>
                <li>
                  An appeal to receive forgiveness of sins and the Holy Spirit
                  dwelling within through immersion baptism (Acts 2:38, I Peter
                  3:21)
                </li>
                <li>
                  An empowered new creature with the potential to live
                  righteously (Corithians II 5:17)
                </li>
              </ul>
            </div>
          </div>
        );
      case "our_congregation":
        return (
          <div>
            <i class="fas fa-hands" />
            <h1>Our Congregation</h1>
            <div className={"title-text"}>
              We're not a denomiation. We share bonds of fellowship with many
              other congregation throughout the country and beyond. We emphasize
              learning as a means to strengthen our faith/change ives. There are
              no bands, smoke machines, singles groups, coffee bar, or bounce
              house, just real people learning to love God and thei neighbor.
            </div>
          </div>
        );
      case "faq":
        return (
          <div>
            <h1>Facts and Questions</h1>
            {this.state.faq.map(el => (
              <div>
                <Divider />
                <h3>{el.q}</h3>
                <p>{el.a}</p>
              </div>
            ))}
          </div>
        );
      default:
        return <div />;
    }
  }

  render() {
    return (
      <div>
        <Header />
        <Fade delay={config.fadeDelay} duration={config.fadeDuration}>
          <div className={"faq"}>
            <div class="uk-margin-medium uk-card uk-card-default uk-card-body">
              <article class="uk-article">
                <h1 class="uk-article-title">
                  <a class="uk-link-reset" href="">
                    Learn More
                  </a>
                </h1>
                <CardTable
                  perRow={4}
                  elements={[
                    {
                      title: "Our Approach",
                      imgType: "",
                      text: "",
                      cb: () => {
                        window.scrollTo({
                          top: 400,
                          left: 0,
                          behavior: "smooth"
                        });
                        this.setState({ section: "our_approach" });
                      }
                    },
                    {
                      title: "Our Beliefs",
                      imgType: "",
                      text: "",
                      cb: () => {
                        window.scrollTo({
                          top: 400,
                          left: 0,
                          behavior: "smooth"
                        });
                        this.setState({ section: "our_beliefs" });
                      }
                    },
                    {
                      title: "Our Congregation",
                      imgType: "",
                      text: "",
                      cb: () => {
                        window.scrollTo({
                          top: 400,
                          left: 0,
                          behavior: "smooth"
                        });
                        this.setState({ section: "our_congregation" });
                      }
                    },
                    {
                      title: "FAQ",
                      imgType: "",
                      text: "",
                      cb: () => {
                        window.scrollTo({
                          top: 400,
                          left: 0,
                          behavior: "smooth"
                        });
                        this.setState({ section: "faq" });
                      }
                    }
                  ]}
                />
                <Divider />
                {this.renderRouter()}
              </article>
            </div>
          </div>
        </Fade>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FAQ);
