import config from "./config";

const api = {
  listFiles: async () => {
    const content = await fetch(
      `${config.protocol}${config.api}${config.port}/api/list_files`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return content.json();
  },
  readEvents: async () => {
    const content = await fetch(
      `${config.protocol}${config.api}${config.port}/api/read_events`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return content.json();
  },
  readFAQ: async () => {
    const content = await fetch(
      `${config.protocol}${config.api}${config.port}/api/read_faq`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return content.json();
  },
  readVideo: async () => {
    const content = await fetch(
      `${config.protocol}${config.api}${config.port}/api/read_video`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return content.json();
  },
  contactUs: async data => {
    const content = await fetch(
      `${config.protocol}${config.api}${config.port}/api/contact_us`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    );
    return content.json();
  }
};

export default api;
